@import "../../include-media.scss";
.layoutBtnGroup {
  .layoutBtn {
    font-size: 18px;
    padding: 0.6rem 1rem;
  }
}
.searchList {
  .col {
    padding-bottom: 15px;
  }
  .card {
    margin: 0;
    transition: box-shadow .15s ease-in;
    .body:hover {
      box-shadow: none !important;
    }
    &:hover {
      box-shadow: 0 0 15px rgba(0,0,0,.125);
    }
  }
  .cardFooter {
    border-top: 0;
    background: transparent;
  }
  @include media('>=desktop') {
    .col {
      padding-bottom: 24px;
    }
  }
}
.cardRow {
  .card {
    min-height: 100%;
  }
  .cardFooter {
    margin-top: -1.5rem;
  }
}

.listRow {
  @include media('>=desktop'){
    .card {
      flex-direction: row;
    }
    .cardFooter {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //align-items: space-between;
      padding-bottom: 1.5rem;
      max-width: 380px;
    }
  }
}
