$orange:     #cb4d27;
$orange-light:     #e47a3b;

// to allow the "generate report" card to be sticky, no parent can have "overflow
.wrapper, .content-page {
    overflow: inherit !important;
}

// the nav bar items should always have padding, even the first one
.topbar-nav .side-nav-item .side-nav-link {
    padding: 1rem 1.3rem !important;
}

// highlight the current nav item
.topbar-nav li.mm-active {
    background-color: #727cf5;
}

// Faded out the pages which does not has the permission to open
.restricted {
	filter: blur(1px);
    pointer-events: none;
}

// allow the rsuite rangeslider to hide the dots, but keep the gradation text
.rs-slider-graduator > ul > li::before {
    display: none !important;
}
// on sliders with few marks, the last slider mark is always a little from the end
// prob because we're only including the rsuite-slider.css, not the full rsuite-common (700KB!)
.push-out-last-slidermark {
	.rs-slider-mark-last {
		left: unset;
		right: 2px;
		.rs-slider-mark-content {
			margin-left: 0%;
		}
	}
}

.page-title {
    font-weight: 700;
}

.edit-search-params {
    ul {
        padding-left: 1em;
    }
    li {
        list-style-type: none;
        .delete {
            cursor: pointer;
        }
    }
}

body {
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

.container-fluid.container-sm {
	padding-top: 6px;
	padding-bottom: 6px;
}
.container-fluid {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.authentication-bg .card {
	border-radius: 10px;
	box-shadow: 0px 0px 25px rgba(0,0,0,.35);
}

.authentication-bg .card-header {
	background: linear-gradient(#000000, #252525);
}

.authentication-bg .btn {
	text-transform: uppercase;
	width: 100%;

}

.vectorlogo {
	max-width: 100%;
	display: block;
}

.btn-primary {
	width: 100%;
	max-width: 380px;
	&:hover {
		border-color: $orange-light;
		background-color: $orange-light;
	}
}

.btn-success {
	background: #128040;
}

.topbar-right-menu button.dropdown-toggle {
	background: #222222 !important;
	border: none;
}

.footer {
	background: #222222;
}

.card .header-title {
	font-size: 1.25rem;
}

.card {
	.vectorlogo {
		max-width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
}

.card-body {
	box-shadow: 0px 0px 15px rgba(0,0,0,0);
	transition: box-shadow .25s ease-in;
	p {
		line-height: 1.25;
	}
}

.card-body:hover {
	transition: box-shadow .15s ease-in;
	box-shadow: 0px 0px 15px rgba(0,0,0,.125);
}

.topbar-nav li.mm-active {
	background: #246EB9 !important;
	font-weight: 700;
}

.topnav-logo {
	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

.content-page {
	background: linear-gradient(#FFFFFF, #e6e6e6);
}

.page-title-box .page-title {
	font-size: 1.75rem;
	color: #b8b8b8 !important;
	font-weight: 300 !important;
}

.btn.btn-secondary.disabled, .btn.btn-secondary {
	border: none;
}

.btn.btn-secondary.disabled {
	background: #ECECEC;
	color: #555555;
}

.button-menu-mobile i.mdi.mdi-menu {
	color: #999999 !important;
}

.ml-2.btn.btn-primary {
	width: auto !important;
}

.footer-links a:hover {
	color: #ECECEC !important;
}

// To restrict the page for the user based on their role
.permission-card-section{
	position: relative;
}

.permission-card{
	position: absolute;
	top: 10%;
	left: 40%;
}

.permission-card .permission{
    padding: 85px;
    box-shadow: 0 0 30px;
	border-radius: 8px;
	background-color: #cb4d27;
}

.permission-card .permission .permission-header{
	font-size: 18px;
	font-weight: 700;
	color: #ffffff;
}

.permission-card .permission .consumer-data-permission-headers{
	font-size: 15px;
	font-weight: 700;
	color: #ffffff;
}

@media only screen and (max-width: 600px)  {
	.input-group {
			margin-top: .5rem;
			margin-bottom: .5rem;
	}
}

.modal .btn {
	width: auto;
	font-size: .9rem;
	line-height: 1.25;
}

.modal-header {
	background: #222222;
}

.modal-header h5 {
	color: #ECECEC;
}

.password-strength {
	&.bad {
		color: #fa5c7c;
	}
	&.weak {
		color: #dd9332;
	}
	&.ok {
		color: #26860e;
	}
}

// the slider checkboxes show on top of select options
.custom-control {
	z-index: auto;
}

.inline-zip-radius {
	display: inline;
	width: 4rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.small{
	padding-left: 5px;
	padding-top: 9px;
	padding-right: 5px;
}

.link {
	text-decoration: underline;
	cursor: pointer;
}

.license-groups-card-border {
	border: 1px solid #a5afb9 !important;
}

.license-clause-parent {
	margin-top: 5px;
	>.label {
		font-weight: bold;
	}
}


.card.shared-with-me {
	background-color: #ddfad6;
}

.card.shared {
	background-color: #fdf9e2;
}


.paginator-rows-per-page {
	width: 70px;
}

// table style definitons
.rs-table-row-header {
	color: #444 !important;
}

.rs-table-body-wheel-area {
	// let row backgrounds be full-width, even when there aren't so many columns
	width: 100%;
}

.rs-table-cell-group-fixed-left {
	// show a border to the right of the fixed cols
	border-right: 1px solid #CCC;
	.rs-table-cell {
		// all fixed cells are a little darker
		background-color: rgba(51, 51, 51, 0.05) !important;
	}
}

.table-striped .rs-table-body-row-wrapper {
	// show green bg for alternating table rows
    div.rs-table-row {
        &:nth-of-type(odd) {
			background-color: lighten($orange-light, 40%);
			&:hover {
				background: #f5f5f5;
			}

			.rs-table-cell-group {
				background-color: lighten($orange-light, 40%);
			}
        }
		&.row-hovered:hover {
			cursor: pointer;
		}
    }
	.rs-table-cell {
		background: none;
	}
}

.table-rows-clickable .rs-table-cell-group-fixed-left .rs-table-cell[role=gridcell] .rs-table-cell-content {
	// if the rows are clickable, make fixed cells look clickable
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

.rs-table-cell-header {
	.rs-table-cell-content {
		overflow: visible;
		white-space: normal;
		font-weight: bold;
		font-size: 14px !important;
	}
}

.rs-table-word-wrap {
	.rs-table-cell-header, .rs-table-cell {
		.rs-table-cell-content {
			word-break: break-word;
		}
	}
}

// agent details modal popup
.agent-details-modal {
	max-width: 1000px;

	h2, h3, h4 {
		color: $orange;
	}
}

.green {
	color: #128040;
}
.tableheading {
	font-size: 1.2em;
}

.bool-attrib-inactive {
	color: #AAA;
	text-decoration: line-through;
}

.ria-firm-asset-filter-row {
	border-bottom: 1px solid #CCC;
	&.odd {
		background-color: #fafafa56;
    	box-shadow: 1px 1px 4px #ccc;
	}
}

.license{
    color: #cb4d27;
}

/* CONTROLS */
.c-controls {
	position: fixed;
	top: calc(50% - 20px);
	margin: 0;
	background: #fff;
	border: 1px solid #e5e5e5;
	color: #ce7a49;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
	font-size: 25px;
	width: 55px;
	height: 65px;
	text-align: center;
	padding: 0px 0 0;
	z-index: 2;
	line-height: 0;
}

.c-controls.next {
	left: auto;
	right: 98px;
	border-radius: 25px;
	width: 50px;
	height: 50px;
}

.c-controls.prev {
	left: 75px;
	border-radius: 25px;
	width: 50px;
	height: 50px;
}

.material-icons.next-btn-text {
	position: absolute;
	bottom: -25px;
	left: 5px;
	font-size: 24px;
	color: #fff;
}
.span-values {
    justify-content: center;
    align-items: center;
    display: grid;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    font-family: sans-serif;
	color: #cb4d27;
	height: 30rem;
}
.dynamic-header .modal-content {
    width: 100%;
    height: 40rem;
}