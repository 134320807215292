
.rs-slider {
    position: relative;
}
.rs-slider .rs-tooltip {
    display: none;
}
.rs-slider .rs-tooltip.rs-tooltip-placement-top .rs-tooltip-arrow {
    margin: auto;
    left: 0;
    right: 0;
}
.rs-slider-disabled {
    opacity: 0.3;
    cursor: not-allowed;
}
.rs-slider-disabled .rs-slider-bar,
.rs-slider-disabled .rs-slider-handle::before {
    cursor: not-allowed;
}
.rs-slider-with-mark:not(.rs-slider-vertical) {
    margin-bottom: 29px;
}
.rs-slider-mark {
    position: absolute;
    top: 15px;
    left: -2px;
    white-space: nowrap;
}
.rs-slider-mark-content {
    margin-left: -50%;
}
.rs-slider-last-mark {
    left: auto;
    right: -2px;
}
.rs-slider-last-mark .rs-slider-mark-content {
    margin-left: 50%;
}
.rs-slider-bar {
    height: 6px;
    border-radius: 3px;
    background-color: #f2f2f5;
    cursor: pointer;
}
.rs-slider-bar:hover {
    background-color: #f2f2f5;
}
.rs-slider-vertical .rs-slider-bar {
    height: 100%;
    width: 6px;
}
.rs-slider-graduator {
    width: 100%;
}
.rs-slider-graduator ul,
.rs-slider-graduator li {
    list-style: none;
}
.rs-slider-graduator > ol,
.rs-slider-graduator > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    width: 100%;
}
.rs-slider-graduator > ol > li,
.rs-slider-graduator > ul > li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 1%;
    flex: 1 1 1%;
    position: relative;
}
.rs-slider-graduator > ul > li:last-child::after,
.rs-slider-graduator > ul > li::before {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 2px solid #f2f2f5;
    margin-left: -4px;
    top: -1px;
    -webkit-transition: border-color 0.3s ease-in;
    transition: border-color 0.3s ease-in;
}
.rs-slider-vertical .rs-slider-graduator > ul > li:last-child::after,
.rs-slider-vertical .rs-slider-graduator > ul > li::before {
    top: -4px;
    margin-left: -1px;
}
.rs-slider-graduator > ul > li:last-child::after {
    right: -4px;
}
.rs-slider-vertical .rs-slider-graduator > ul > li:last-child::after {
    left: 0;
    top: auto;
    bottom: -4px;
}
.rs-slider-graduator > ul > li.rs-slider-pass::before {
    border-color: #3498ff;
}
.rs-slider-graduator > ul > li.rs-slider-active::before {
    visibility: hidden;
}
.rs-slider-vertical .rs-slider-graduator {
    display: block;
    height: 100%;
}
.rs-slider-vertical .rs-slider-graduator > ul {
    width: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 0;
}
.rs-slider-vertical .rs-slider-graduator > ul > li {
    display: block;
    padding: 0;
}
.rs-slider-progress-bar {
    position: absolute;
    height: 6px;
    border-radius: 3px 0 0 3px;
    background-color: #3498ff;
}
.rs-slider-vertical .rs-slider-progress-bar {
    width: 6px;
    border-radius: 3px 3px 0 0;
}
.rs-slider-handle {
    position: absolute;
    top: -50%;
}
.rs-slider-handle::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #3498ff;
    background-color: #fff;
    margin-left: -6px;
    cursor: pointer;
    /* stylelint-disable */
    -webkit-transition: background-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    /* stylelint-enable */
}
.rs-slider-handle:hover::before {
    -webkit-box-shadow: 0 0 0 4px rgba(204, 233, 255, 0.4);
    box-shadow: 0 0 0 4px rgba(204, 233, 255, 0.4);
}
.rs-slider-handle.active.rs-slider-handle::before {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.rs-slider-vertical .rs-slider-handle::before {
    left: 3px;
    margin-top: -6px;
}
.rs-slider-handle:hover .rs-tooltip,
.rs-slider-handle.active .rs-tooltip {
    display: block;
    opacity: 1;
    top: -30px;
}
.rs-slider-vertical .rs-slider-handle:hover .rs-tooltip,
.rs-slider-vertical .rs-slider-handle.active .rs-tooltip {
    top: -33px;
    margin-left: 3px;
}
.rs-slider-vertical {
    height: 100%;
}
.rs-slider-vertical .rs-slider-mark {
    top: -8px;
    left: 15px;
}
.rs-slider-vertical .rs-slider-mark-content {
    margin-left: auto;
}
.rs-slider-vertical .rs-slider-last-mark {
    top: auto;
    bottom: -8px;
}

.rs-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-size: 12px;
    opacity: 0;
    line-height: 1.66666667;
}
.rs-tooltip.fade {
    -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
}
.rs-tooltip.in {
    opacity: 1;
    -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
}
.rs-tooltip .rs-tooltip-inner {
    max-width: 250px;
    padding: 2px 10px;
    color: #fff;
    text-align: center;
    background-color: #272c36;
    border-radius: 4px;
    overflow-wrap: break-word;
}
.rs-tooltip .rs-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.rs-tooltip[class*='placement-top'] {
    margin-top: -6px;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.rs-tooltip[class*='placement-top'].in {
    -webkit-transform: translate(0, -2px);
    transform: translate(0, -2px);
}
.rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
    bottom: -6px;
    margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: #272c36;
}
@supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
        bottom: -5px;
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
        bottom: -5px;
    }
}
.rs-tooltip[class*='placement-bottom'] {
    margin-top: 6px;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.rs-tooltip[class*='placement-bottom'].in {
    -webkit-transform: translate(0, 2px);
    transform: translate(0, 2px);
}
.rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
    top: -6px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: #272c36;
}
@supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
        top: -5px;
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
        top: -5px;
    }
}
/* rtl:begin:ignore */
.rs-tooltip[class*='placement-right'] {
    margin-left: 6px;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.rs-tooltip[class*='placement-right'].in {
    -webkit-transform: translate(2px, 0);
    transform: translate(2px, 0);
}
.rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
    left: -6px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
    border-right-color: #272c36;
}
@supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
        left: -5px;
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
        left: -5px;
    }
}
.rs-tooltip[class*='placement-left'] {
    margin-left: -6px;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.rs-tooltip[class*='placement-left'].in {
    -webkit-transform: translate(-2px, 0);
    transform: translate(-2px, 0);
}
.rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
    right: -6px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: #272c36;
}
@supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
        right: -5px;
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
        right: -5px;
    }
}
/* rtl:end:ignore */
.rs-tooltip.placement-bottom .rs-tooltip-arrow,
.rs-tooltip.placement-top .rs-tooltip-arrow {
    left: 50%;
}
.rs-tooltip.placement-bottom-start .rs-tooltip-arrow,
.rs-tooltip.placement-top-start .rs-tooltip-arrow {
    left: 10px;
}
.rs-tooltip.placement-bottom-end .rs-tooltip-arrow,
.rs-tooltip.placement-top-end .rs-tooltip-arrow {
    right: 4px;
}
.rs-tooltip.placement-right .rs-tooltip-arrow,
.rs-tooltip.placement-left .rs-tooltip-arrow {
    top: 50%;
}
.rs-tooltip.placement-right-start .rs-tooltip-arrow,
.rs-tooltip.placement-left-start .rs-tooltip-arrow {
    top: 10px;
}
.rs-tooltip.placement-right-end .rs-tooltip-arrow,
.rs-tooltip.placement-left-end .rs-tooltip-arrow {
    bottom: 4px;
}
