.custom-multiselect {
  .form-control[readonly] {
    opacity: 0;
  }
  .custom-control {
    padding-left: 0;
  }
  .custom-control-label {
    div {
      background: none !important;
    }
    &:before,
    &:after {
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      z-index: -1;
    }
  }
}
:root {
}
